<template>
  <div>
    <v-card class="ma-1 pa-1">
      <v-card-title class="pa-1">
        <span>{{ $t("message.znz_index") }}</span>
        <v-spacer></v-spacer>
        <span class="mr-5"
          ><v-checkbox
            v-model="filter.document_status[0]"
            :label="
              counts.length > 1
                ? document_status[1]['name_' + $i18n.locale] +
                  ' ' +
                  '(' +
                  counts[0].count +
                  ')'
                : ''
            "
            @change="getList"
          ></v-checkbox>
        </span>
        <span class="mr-5"
          ><v-checkbox
            v-model="filter.document_status[1]"
            :label="
              counts.length > 2
                ? document_status[2]['name_' + $i18n.locale] +
                  ' ' +
                  '(' +
                  counts[1].count +
                  ')'
                : ''
            "
            @change="getList"
          ></v-checkbox>
        </span>
        <span class="mr-5">
          <v-checkbox
            v-model="filter.document_status[2]"
            :label="
              counts.length > 2
                ? document_status[6]['name_' + $i18n.locale] +
                  ' ' +
                  '(' +
                  counts[2].count +
                  ')'
                : ''
            "
            @change="getList"
          ></v-checkbox>
        </span>
        <v-spacer></v-spacer>

        <!-- <v-autocomplete
          class="py-0 px-2"
          clearable
          :label="$t('documentTypes.index')"
          v-model="filter.document_type_id"
          :items="document_type"
          dense
          hide-details
          @change="getDocumentTemplate()"
          item-value="id"
          :item-text="'name_' + $i18n.locale"
          style="max-width: 350px"
        >
          <template v-slot:selection="{ item }">
            <v-chip color="white" class="px-1 py-0 ma-0">
              <span v-text="item['name_' + $i18n.locale]"></span>
            </v-chip>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title
                v-text="item['name_' + $i18n.locale]"
              ></v-list-item-title>
            </v-list-item-content>
          </template>
        </v-autocomplete> -->
        <!-- <v-autocomplete
          v-model="filter.reaction_status"
          :items="reaction_status"
          outlined
          dense
          chips
          small-chips
          :label="$t('document.reaction_status')"
          multiple
          hide-details
          style="max-width: 520px; min-width: 466px"
        ></v-autocomplete>
        <v-btn @click="getFilter" icon outlined class="mx-2" color="success">
          <v-icon>mdi-magnify</v-icon>
        </v-btn> -->
      </v-card-title>
      <!-- <v-card class="mx-1 my-3">
        <v-expansion-panels focusable>
          <v-expansion-panel>
            <v-expansion-panel-header v-slot="{ open }">
              <v-row no-gutters>
                <v-col cols="4">{{ $t("filter") }}</v-col>
                <v-col cols="8" class="text--secondary">
                  <v-fade-transition leave-absolute>
                    <span v-if="open">{{ $t("document.full_search") }}</span>
                    <v-row v-else no-gutters style="width: 100%">
                      <v-col cols="6">
                        <span v-if="$i18n.locale == 'ru'">
                          {{
                            filter.document_start_date
                              ? $t("start_date") +
                                " " +
                                format_date(filter.document_start_date) +
                                " " +
                                $t("start_date2")
                              : "Not set"
                          }}
                        </span>
                        <span v-else>
                          {{
                            filter.document_start_date
                              ? format_date(filter.document_start_date) +
                                " " +
                                $t("start_date")
                              : "Not set"
                          }}
                        </span>
                      </v-col>
                      <v-col cols="6">
                        <span v-if="$i18n.locale == 'ru'">
                          {{
                            filter.document_end_date
                              ? $t("end_date") +
                                " " +
                                format_date(filter.document_end_date) +
                                " " +
                                $t("start_date2")
                              : "Not set"
                          }}
                        </span>
                        <span v-else>
                          {{
                            filter.document_end_date
                              ? format_date(filter.document_end_date) +
                                " " +
                                $t("end_date")
                              : "Not set"
                          }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-fade-transition>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="6" md="4" lg="3" xl="2" class="px-1">
                  <v-menu
                    ref="startMenu"
                    :close-on-content-click="false"
                    :return-value.sync="filter.document_start_date"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filter.document_start_date"
                        label="Start date"
                        v-bind="attrs"
                        v-on="on"
                        dense
                        outlined
                        clearable
                        hide-details
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.startMenu.isActive = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.startMenu.save(date)"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="px-1">
                  <v-menu
                    ref="endMenu"
                    :close-on-content-click="false"
                    :return-value.sync="filter.document_end_date"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filter.document_end_date"
                        label="End date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        outlined
                        hide-details
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.endMenu.isActive = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.endMenu.save(date)"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="px-1">
                  <v-text-field
                    v-model="filter.document_number"
                    dense
                    :label="$t('document.document_number')"
                    outlined
                    type="text"
                    hide-details
                    clearable
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4" lg="3" class="px-1">
                  <v-autocomplete
                    class="py-0"
                    clearable
                    :label="$t('documentTemplates.index')"
                    v-model="filter.document_template_id"
                    :items="document_templates"
                    dense
                    hide-details
                    item-value="id"
                    outlined
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip color="white" class="px-1 py-0 ma-0">
                        <span v-text="item.text"></span>
                      </v-chip>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="4" lg="3" class="px-1">
                  <v-autocomplete
                    v-model="filter.reaction_status"
                    :items="reaction_status"
                    outlined
                    dense
                    chips
                    small-chips
                    :label="$t('document.reaction_status')"
                    multiple
                    hide-details
                  ></v-autocomplete>
                </v-col>

                <v-col cols="6" md="4" lg="3" xl="2" class="px-1">
                  <v-btn @click="getFilter">
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card> -->
      <v-data-table
        dense
        fixed-header
        :loading-text="$t('loadingText')"
        :no-data-text="noDataText"
        :height="screenHeight"
        :loading="loading"
        :headers="headers"
        :items="items"
        class="ma-1"
        style="border: 1px solid #aaa"
        item-key="id"
        :server-items-length="server_items_length"
        :options.sync="dataTableOptions"
        :disable-pagination="true"
        :footer-props="{
          itemsPerPageOptions: [50, 100, 200, -1],
          itemsPerPageAllText: $t('itemsPerPageAllText'),
          itemsPerPageText: $t('itemsPerPageText'),
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-arrow-left',
          nextIcon: 'mdi-arrow-right',
        }"
        @update:page="updatePage"
        @update:items-per-page="updatePerPage"
        :expanded.sync="expanded"
        single-expand
        show-expand
        @update:expanded="toggleExpand"
        @dblclick:row="rowClick"
      >
        <template v-slot:body.prepend>
          <tr class="py-0 my-0">
            <td class="py-0 my-0 dense"></td>
            <td class="py-0 my-0 dense">
              <v-text-field
                v-model="filter.document_number"
                type="text"
                hide-details
                @keyup.native.enter="getFilter()"
                clearable
              ></v-text-field>
            </td>
            <td class="py-0 my-0 dense">
              <v-menu
                ref="rangeMenu"
                :close-on-content-click="false"
                :return-value.sync="filter.document_range"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filter.document_range"
                    v-bind="attrs"
                    @keyup.native.enter="getFilter()"
                    v-on="on"
                    dense
                    hide-details
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" range no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.rangeMenu.isActive = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="
                      $refs.rangeMenu.save(date);
                      filter.document_range = date;
                      getFilter();
                    "
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </td>
            <td class="py-0 my-0 dense">
              <!-- <v-autocomplete
                class="py-0"
                clearable
                v-model="filter.document_template_id"
                :items="document_templates"
                dense
                hide-details
                item-value="id"
                @change="getFilter()"
              >
                <template v-slot:selection="{ item }">
                  {{ item.text }}
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete> -->
            </td>
            <td class="py-0 my-0 dense" style="min-width: 140px"></td>
            <td class="py-0 my-0 dense"></td>
            <td class="py-0 my-0 dense" style="min-width: 130px"></td>
            <td class="py-0 my-0 dense" style="min-width: 130px">
              <v-text-field
                v-model="filter.pending_action"
                type="text"
                hide-details
                @keyup.native.enter="getFilter()"
                clearable
              ></v-text-field>
            </td>
            <td class="py-0 my-0 dense"></td>
            <td class="py-0 my-0 dense"></td>
          </tr>
        </template>
        <template v-slot:item.document_number="{ item }">
          <!-- <a :href="'#/documents/show/'+item.id">{{item.document_number}}</a> -->
          <!-- {{ signered[items.map(function(x) {return x.id; }).indexOf(item.id) + from - 1] }} -->
          <v-btn
            outlined
            small
            :dark="item.reaction_status == 0 ? false : true"
            rounded
            :class="
              item.reaction_status == 1
                ? 'success'
                : item.reaction_status == 2
                ? 'error'
                : item.reaction_status == 3
                ? 'deep-purple'
                : ''
            "
            :to="'/document/' + item.pdf_file_name"
            >{{ item.document_number }}</v-btn
          >
        </template>
        <template v-slot:item.department_send="{ item }">
          {{
            item.employee.employee_staff &&
            item.employee.employee_staff[0].staff.department[
              "name_" + $i18n.locale
            ]
          }}
        </template>
        <template v-slot:item.creator="{ item }">
          {{
            $i18n.locale == "uz_latin"
              ? item.employee.lastname_uz_latin +
                " " +
                item.employee.firstname_uz_latin.substr(0, 1) +
                "." +
                item.employee.middlename_uz_latin.substr(0, 1) +
                "."
              : item.employee.lastname_uz_cyril +
                " " +
                item.employee.firstname_uz_cyril.substr(0, 1) +
                "." +
                item.employee.middlename_uz_cyril.substr(0, 1) +
                "."
          }}
        </template>
        <template v-slot:item.department_receiver="{ item }">
          {{
            item.department && item.department.manager_staff
              ? item.department.manager_staff.employees.length
                ? item.department.manager_staff.employees[0][
                    "lastname_" + language
                  ] +
                  " " +
                  item.department.manager_staff.employees[0][
                    "firstname_" + language
                  ].substr(0, 1) +
                  "." +
                  item.department.manager_staff.employees[0][
                    "middlename_" + language
                  ].substr(0, 1) +
                  "."
                : item.department.manager_staff.position["name_" + $i18n.locale]
              : item.department["name_" + $i18n.locale]
          }}
        </template>
        <template v-slot:item.document_type="{ item }">{{
          item.document_type["name_" + $i18n.locale]
        }}</template>
        <template v-slot:item.document_template="{ item }">
          {{
            item.document_template &&
            item.document_template["name_" + $i18n.locale]
          }}
        </template>
        <template v-slot:item.document_date="{ item }">
          {{
            item.document_date.substr(0, 10) +
            " " +
            item.document_date.substr(11, 5)
          }}
        </template>
        <template v-slot:item.status="{ item }" style="padding: 0px">
          <span
            v-if="item.status == 0"
            style="
              display: block;
              background: #757575;
              color: #fff;
              border-radius: 10px;
              padding: 1px 5px;
            "
          >
            {{
              document_status[item.status]
                ? document_status[item.status]["name_" + $i18n.locale]
                : ""
            }}
          </span>
          <span
            v-if="item.status == 1"
            style="
              display: block;
              background: #00acc1;
              color: #fff;
              border-radius: 10px;
              padding: 1px 5px;
            "
          >
            {{
              document_status[item.status]
                ? document_status[item.status]["name_" + $i18n.locale]
                : ""
            }}
          </span>
          <span
            v-if="item.status == 2"
            style="
              display: block;
              background: #039be5;
              color: #fff;
              border-radius: 10px;
              padding: 1px 5px;
            "
          >
            {{
              document_status[item.status]
                ? document_status[item.status]["name_" + $i18n.locale]
                : ""
            }}
          </span>
          <span
            v-if="item.status == 3"
            style="
              display: block;
              background: teal;
              color: #fff;
              border-radius: 10px;
              padding: 1px 5px;
            "
          >
            {{
              document_status[item.status]
                ? document_status[item.status]["name_" + $i18n.locale]
                : ""
            }}
          </span>
          <span
            v-if="item.status == 4"
            style="
              display: block;
              background: #d8cd1d;
              color: #fff;
              border-radius: 10px;
              padding: 1px 5px;
            "
          >
            {{
              document_status[item.status]
                ? document_status[item.status]["name_" + $i18n.locale]
                : ""
            }}
          </span>
          <span
            v-if="item.status == 5"
            style="
              display: block;
              background: #00c853;
              color: #fff;
              border-radius: 10px;
              padding: 1px 5px;
            "
          >
            {{
              document_status[item.status]
                ? document_status[item.status]["name_" + $i18n.locale]
                : ""
            }}
          </span>
          <span
            v-if="item.status == 6"
            style="
              display: block;
              background: #ef6c00;
              color: #fff;
              border-radius: 10px;
              padding: 1px 5px;
            "
          >
            {{
              document_status[item.status]
                ? document_status[item.status]["name_" + $i18n.locale]
                : ""
            }}
          </span>
        </template>
        <template v-slot:item.pending_action="{ item }">
          <template v-for="document_signer in item.document_signers">
            <div
              :key="document_signer.id"
              class="ma-0"
              v-if="
                item.status != 6 &&
                (document_signer.status == 0 || document_signer.status == 3)
              "
            >
              <div v-if="document_signer.signer_employee">
                {{
                  document_signer.signer_employee &&
                  document_signer.signer_employee["lastname_" + language] +
                    " " +
                    document_signer.signer_employee[
                      "firstname_" + language
                    ].substr(0, 1) +
                    "." +
                    document_signer.signer_employee[
                      "middlename_" + language
                    ].substr(0, 1) +
                    "."
                }}
              </div>
              <div v-else>
                {{
                  document_signer.employee_staffs &&
                  document_signer.employee_staffs.employee[
                    "lastname_" + language
                  ] +
                    " " +
                    document_signer.employee_staffs.employee[
                      "firstname_" + language
                    ].substr(0, 1) +
                    "." +
                    document_signer.employee_staffs.employee[
                      "middlename_" + language
                    ].substr(0, 1) +
                    "."
                }}
              </div>
            </div>
          </template>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            v-if="item.created_employee_id == user.employee_id && false"
            color="primary"
            class="px-1"
            small
            icon
            @click="$router.push('/document/copy/' + item.id)"
          >
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
          <v-btn
            color="success"
            class="px-1"
            small
            icon
            @click="$router.push('/document/' + item.pdf_file_name)"
          >
            <v-icon>mdi-eye-outline</v-icon>
          </v-btn>
          <v-btn
            class="px-1"
            v-if="
              $store.getters.checkPermission('document-update') &&
              item.status < 1
            "
            color="blue"
            small
            icon
            @click="$router.push('/document/update/' + item.id)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            v-if="item.status == 0"
            color="red"
            class="px-1"
            small
            icon
            @click="deleteItem(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-row class="justify-center">
              <v-col cols="12" md="10" lg="9" xl="7" class="py-1 ma-2">
                <v-card class="pa-3">
                  <div
                    v-for="(
                      document_detail, detail_index
                    ) in item.document_details"
                    :key="detail_index"
                  >
                    <strong
                      style="float: left"
                      v-if="item.document_details.length > 1"
                      >{{ detail_index + 1 }}.</strong
                    >
                    <p
                      class="text-left font-weight-black my-1 pl-6"
                      v-html="document_detail.content"
                    >
                      <!-- {{  }} -->
                    </p>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <tbody>
                          <tr
                            v-for="item in document_detail.document_detail_attribute_values"
                            :key="item.index"
                          >
                            <td class="text-right px-2">
                              <b>
                                {{
                                  item.document_detail_attributes[
                                    "attribute_name_" + $i18n.locale
                                  ]
                                }}:
                              </b>
                            </td>
                            <td class="text-left px-2" width="50%">
                              {{ item.attribute_value }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="loading" width="300" hide-overlay>
      <v-card color="primary" dark>
        <v-card-text>
          {{ $t("loadingText") }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
const axios = require("axios").default;
const moment = require("moment");
import Swal from "sweetalert2";
import Axios from "axios";
const Cookies = require("js-cookie");
// import moment from 'moment';
export default {
  data() {
    return {
      date: null,
      loading: false,
      isLoading: false,
      expanded: [],
      search: "",
      dialog: false,
      editMode: null,
      items: [],
      form: {},
      dialogHeaderText: "",
      dataTableOptions: { page: 1, itemsPerPage: 50 },
      page: 1,
      from: 0,
      server_items_length: -1,
      document_type: [],
      counts: [],
      document_template: {
        document_detail_templates: [
          {
            document_detail_attributes: {},
          },
        ],
      },
      document_templates: [],
      filter: {
        document_status: [true, true, true],
        document_type_id: "",
        document_template_id: "",
        document_start_date: "",
        document_end_date: "",
        attributes: [],
        menu_item: "",
        document_number: "",
        reaction_status: [0, 1, 2, 3],
      },
      showFilter: false,
      menu: [],
      tableLists: [],
      table_name: [],
      column_name: [],
      is_locale: [],
      headers: [
        { text: "", value: "data-table-expand" },
        // { text: "#", value: "id", align: "center", width: 30 },
        {
          text: this.$t("document.document_number"),
          value: "document_number",
          align: "center",
          width: 80,
          sortable: false,
        },
        {
          text: this.$t("document.document_date"),
          value: "document_date",
          width: 150,
          sortable: false,
        },
        {
          text: this.$t("document.document_type"),
          value: "document_template",
          width: 150,
          sortable: false,
        },
        {
          text: this.$t("document.creator"),
          value: "creator",
          sortable: false,
        },
        {
          text: this.$t("document.department_send"),
          value: "department_send",
          sortable: false,
        },
        {
          text: this.$t("document.department_receiver"),
          value: "department_receiver",
          sortable: false,
        },
        {
          text: this.$t("document.pending_action"),
          value: "pending_action",
          width: 150,
          sortable: false,
        },
        {
          text: this.$t("document.status"),
          value: "status",
          align: "center",
          width: 180,
          sortable: false,
        },
        {
          text: this.$t("actions"),
          value: "actions",
          sortable: false,
        },
      ],
      document_status: [
        {
          id: 0,
          name_uz_latin: "Yangi",
          name_uz_cyril: "Янги",
          name_ru: "Новый",
        },
        {
          id: 1,
          name_uz_latin: "E'lon qilindi",
          name_uz_cyril: "Эьлон қилинди",
          name_ru: "Опубликованный",
        },
        {
          id: 2,
          name_uz_latin: "Ko`rib chiqilmoqda",
          name_uz_cyril: "Кўриб чиқилмоқда",
          name_ru: "Обработка",
        },
        {
          id: 3,
          name_uz_latin: "Imzolandi",
          name_uz_cyril: "Имзоланди",
          name_ru: "Подписано",
        },
        {
          id: 4,
          name_uz_latin: "Bajarildi",
          name_uz_cyril: "Бажарилди",
          name_ru: "Выполнено",
        },
        {
          id: 5,
          name_uz_latin: "Yakunlandi",
          name_uz_cyril: "Якунланди",
          name_ru: "Завершено",
        },
        {
          id: 6,
          name_uz_latin: "Bekor qilindi",
          name_uz_cyril: "Бекор қилинди",
          name_ru: "Отменен",
        },
      ],
      reaction_status: [
        {
          text: this.$t("document.new"),
          value: 0,
        },
        {
          text: this.$t("document.ok"),
          value: 1,
        },
        {
          text: this.$t("document.cancel"),
          value: 2,
        },
        {
          text: this.$t("document.process"),
          value: 3,
        },
      ],
      noDataText: "",
    };
  },
  computed: {
    screenHeight() {
      return window.innerHeight - 175;
    },
    user() {
      return this.$store.getters.getUser();
    },
    language() {
      return this.$i18n.locale == "ru" ? "uz_cyril" : this.$i18n.locale;
    },
  },
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD.MM.YYYY");
      }
    },
    updatePage($event) {
      this.getList();
    },
    updatePerPage($event) {
      this.getList();
    },
    toggleExpand($event) {},
    rowClick(item, row) {
      row.expand(!row.isExpanded);
      // if (this.expanded[0] && this.expanded[0].id == item.id) this.expanded = [];
      // else this.expanded = [item];
    },
    getList() {
      this.loading = true;
      this.noDataText = this.$t("loadingText");
      axios
        .post(this.$store.state.backend_url + "api/reports/znz", {
          pagination: this.dataTableOptions,
          language: this.$i18n.locale,
          filter: this.filter,
        })
        .then((response) => {
          this.items = response.data.documents.data;
          this.table_list_value = response.data.table_list_value;
          this.items.map((document, index) => {
            document.reaction_status = 0;
            document.document_signers.map((document_signer) => {
              if (this.user.employee_id == document_signer.signer_employee_id) {
                document.reaction_status = document_signer.status;
              }
            });
            return document;
          });
          if (!this.items.length) {
            this.noDataText = this.$t("noDataText");
          }
          // console.log(this.items);
          this.server_items_length = response.data.documents.total;
          this.from = response.data.documents.from;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    getStatusCount() {
      this.loading = true;
      axios
        .get(this.$store.state.backend_url + "api/documents/znz-count")
        .then((response) => {
          this.counts = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    getDocumentTemplate() {
      this.loading = true;
      axios
        .post(
          this.$store.state.backend_url + "api/document-templates/get-list",
          {
            language: this.$i18n.locale,
          }
        )
        .then((res) => {
          this.document_templates = res.data;
          this.loading = false;
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
        });
    },
    getDocumentType() {
      this.filter.attributes = [];
      axios
        .get(this.$store.state.backend_url + "api/document-types")
        .then((response) => {
          this.document_type = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    getFilter() {
      this.showFilter = true;
      this.document_template = this.document_templates.find((v) => {
        return v.id == this.filter.document_template_id;
      });
      // console.log(this.filter);
      Cookies.set("filter", this.filter);
      this.getList();
    },
    editItem(item) {},
    deleteItem(item) {
      Swal.fire({
        title: this.$t("swal_title"),
        text: this.$t("swal_text"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("swal_delete"),
      }).then((result) => {
        if (result.value) {
          if (item.status == 0) {
            axios
              .delete(
                this.$store.state.backend_url +
                  "api/documents/delete/" +
                  item.id
              )
              .then((res) => {
                Swal.fire("Deleted!", this.$t("swal_deleted"), "success");
                this.getList();
              })
              .catch((err) => {
                Swal.fire({
                  icon: "error",
                  title: this.$t("swal_error_title"),
                  text: this.$t("swal_error_text"),
                  //footer: "<a href>Why do I have this issue?</a>"
                });
                console.log(err);
              });
          }
        }
      });
    },
  },
  watch: {
    $route(to, from) {
      this.filter.menu_item = this.$route.params.menu_item;
      this.filter.document_type_id = this.$route.params.document_type;
      Cookies.set("filter", this.filter);
      this.getList();
    },
  },
  mounted() {
    // if (Cookies.get("filter")) {
    //   this.filter = JSON.parse(Cookies.get("filter"));
    // } else {
    //   this.filter.reaction_status = [0, 1, 2, 3];
    // }
    // console.log(Cookies.get("filter"));
    this.getDocumentTemplate();
    this.getList();
    this.getStatusCount();
  },
  created() {},
};
</script>
<style scoped>
.v-item--active {
  background-color: #fff !important;
}
.dense {
  padding: 0px;
  height: 10px !important;
}

.dense .v-text-field__details {
  display: none !important;
}

.dense .v-text-field {
  padding: 0px;
  margin: 0px;
}

.dense div div div {
  margin-bottom: 0px !important;
}
</style>
